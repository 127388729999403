import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";
import Colors from "../../utils/Colors";
import DoubtsMessage from "./DoubtsMessage";
import { isEmpty } from "../../functions/isEmpty";
import { set, ref, push } from "firebase/database";
import { useUserContext } from "../../utils/UserContext";
import PrimaryButton from "../../components/PrimaryButton";
import { cleanUpInput } from "../../functions/cleanUpInput";
import messageSound from "../../assets/newMessageSound.mp3";
import LottieAnimation from "../../components/LottieAnimation";
// import { MathJax, MathJaxContext } from "better-react-mathjax";
import { useIsMobileDevice } from "../../hooks/useIsMobileDevice";
import styles from "../../styles/doubtsStyles/DoubtsChat.module.css";
import {
  db,
  getTextAnswer,
  getChatTitle,
  useSyncWithFirebase,
  getImageAnswer,
} from "../../utils/firebase";

const questionSuggestions = ["Explain in Hindi", "Explain More", "Explain in Hinglish"];

const DoubtsChat = ({ subject, chatKey, initialQuestion, setChatKey }) => {
  const chatEndRef = useRef(null);
  const latestImageRef = useRef(null);
  const isMobileDevice = useIsMobileDevice();
  const { user, brand, chatsPath, conversationsDisplayPath } = useUserContext();
  const [isLoading, setIsLoading] = useState(false);
  const [chat, setChat] = useState({});
  const isChatEmpty = isEmpty(chat);
  const [isImageUploading, setIsImageUploading] = useState(false);
  const chatsArray = useMemo(() => (!isEmpty(chat) ? Object.values(chat) : []), [chat]);
  const audioRef = useRef(new Audio(messageSound));

  const formatAnswer = (input) => {
    // Replace **text** with Semibold text using inline CSS
    const formattedText = input
      .replace(/\*\*(.*?)\*\*/g, '<span style="font-weight: 600;font-size: 1.1em">$1</span>')
      .replace(
        /###(.*?)\n/g,
        '<span style="font-weight: 500; font-size: 1.3em; color: #121212;">$1</span><br />'
      )
      .replace(/\\\((.*?)\\\)/g, '<span style="font-size: 1.25em; font-weight: 400;">∫ $1</span>')

      // Replace in Good Fraction
      .replace(/\\frac\{(.*?)\}\{(.*?)\}/g, "$1/$2")

      // Remove only the backslashes followed by a comma (\,)
      .replace(/\\,/g, "")

      // Replace \cdot with a dot "."
      .replace(/\\cdot/g, ".")

      // Remove backslashes from trigonometric and other functions
      .replace(/\\(sin|cos|tan|log|ln|exp|sec|csc|cot|arg)/g, "$1")

      // Handle \left| ... \right| by replacing with HTML absolute value representation
      .replace(/\\left\|([^\\]+)\\right\|/g, (match, content) => {
        return `|${content}|`; // Replace with absolute value bars
      })

      .replace(/\\left\((.*?)\\right\)/g, (match, content) => {
        return `(${content})`; // Replace with normal parentheses
      })

      // Replace \Delta with Δ
      .replace(/\\Delta/g, "Δ")

      // Subscript
      .replace(/([a-zA-Z0-9]+)_([a-zA-Z0-9]+)/g, "$1<sub>$2</sub>")
      .replace(/([a-zA-Z0-9]+)_{([^}]+)}/g, "$1<sub>$2</sub>") // for x_{T1}
      .replace(/([a-zA-Z0-9]+)_\(([^)]+)\)/g, "$1<sub>$2</sub>") // for x_(T1)
      .replace(/([a-zA-Z0-9]+)_\[(^[\]]+)\]/g, "$1<sub>$2</sub>") // for x_[T1]

      // Handle \boxed{...} by wrapping it in a styled box
      .replace(/\\boxed\{(.*?)\}/g, (match, content) => {
        return `<span style="border: 2px solid #000; padding: 5px; display: inline-block;">${content}</span>`;
      })

      // Remove \[ and \] (mathematical expression brackets)
      .replace(/\\\[|\\\]/g, "")

      // Replace /int
      .replace(/\\int\s*(.*?)(\\)?/g, (match, content, trailingBackslash) => {
        // Replace \int with ∫
        let result = `<span style="font-size: 1.25em; font-weight: 400;">∫ ${content}</span>`;
        // If there's a trailing backslash, add a backslash formatting or remove it based on your requirement
        if (trailingBackslash) {
          result += " \\";
        }
        return result;
      })

      // Replace ^ with superscript
      .replace(/\^(\{[^}]*\}|\[[^\]]*\]|\([^)]*\)|[^\s^]+)/g, (match, p1) => {
        const superscriptMap = {
          1: "¹",
          2: "²",
          3: "³",
          4: "⁴",
          5: "⁵",
          6: "⁶",
          7: "⁷",
          8: "⁸",
          9: "⁹",
          0: "⁰",
          n: "ⁿ", // 'n' becomes 'ⁿ'
          "+": "⁺", // '+' becomes '⁺'
          "-": "⁻", // '-' becomes '⁻'
          "=": "⁼", // '=' becomes '⁼'
          "/": "⁄", // '/' becomes '⁄'
          "{": "❨", // '{' becomes '❨'
          "}": "❩", // '}' becomes '❩'
          "[": "❮", // '[' becomes '❮'
          "]": "❯", // ']' becomes '❯'
        };

        // Handle cases for matched expressions inside parentheses, braces, or brackets
        if (p1.startsWith("(") && p1.endsWith(")")) {
          return (
            "^" +
            p1
              .slice(1, -1)
              .split("")
              .map((char) => {
                return superscriptMap[char] || char; // Convert to superscript if mapped
              })
              .join("")
          );
        } else if (p1.startsWith("{") && p1.endsWith("}")) {
          return (
            "^" +
            p1
              .slice(1, -1)
              .split("")
              .map((char) => {
                return superscriptMap[char] || char; // Convert to superscript if mapped
              })
              .join("")
          );
        } else if (p1.startsWith("[") && p1.endsWith("]")) {
          return (
            "^" +
            p1
              .slice(1, -1)
              .split("")
              .map((char) => {
                return superscriptMap[char] || char; // Convert to superscript if mapped
              })
              .join("")
          );
        }

        // For other expressions, just replace individual characters
        return p1
          .split("")
          .map((char) => superscriptMap[char] || char)
          .join("");
      })

      // Remove \[ and \]
      .replace(/\\\[|\\\]/g, "");

    // Add single line break for any remaining newlines
    return formattedText.replace(/\n/g, "<br />");
  };

  const generateChatTitle = async (input) => {
    try {
      const response = await getChatTitle({ question: input });
      return JSON.parse(response.data)?.title || "Your Question";
    } catch (error) {
      console.error("Error getting title for text:", error);
      return "Your Question";
    }
  };

  // Scrolling to Bottom
  useEffect(() => {
    if (chatEndRef.current) {
      chatEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [chat]);

  useEffect(() => {
    if (!chatKey) {
      setChat({});
    }
  }, [chatKey]);

  // Adding message to chat
  const addToChat = useCallback(
    async (role, content, inputChatKey) => {
      let finalChatKey = inputChatKey || chatKey;

      if (!finalChatKey) {
        finalChatKey = push(ref(db, chatsPath)).key;
        const title = await generateChatTitle(content);
        const conversationDisplay = {
          title,
          subject,
          key: finalChatKey,
          isImportant: false,
          time: new Date().getTime(),
        };

        sessionStorage.setItem(subject, finalChatKey);
        set(ref(db, `${conversationsDisplayPath}/${finalChatKey}`), conversationDisplay);
      }

      const messageKey = push(ref(db, `${chatsPath}/${finalChatKey}`)).key;
      set(ref(db, `${chatsPath}/${finalChatKey}/${messageKey}`), { role, content });
      setChatKey(finalChatKey);

      // Play sound if the role is 'assistant'
      if (role === "assistant") {
        audioRef.current.play().catch((error) => console.error("Audio playback failed:", error));
      }

      // Get Answer if User has asked the question
      if (role === "user") {
        if (!content.startsWith("<img")) {
          const backendInputs = {
            description: `Give answer according to ${subject} of class ${user?.selectedClass}`,
            conversation: [...chatsArray, { role, content }],
          };
          setIsLoading(true);
          try {
            const response = await getTextAnswer(backendInputs);
            const answer = response.data;
            addToChat("assistant", formatAnswer(answer), finalChatKey);
          } catch (error) {
            console.error("Error getting answer:", error);
          }
        } else if (content.startsWith("<img")) {
          setIsLoading(true);
          try {
            const response = await getImageAnswer({ image: latestImageRef.current });
            console.log(response);
            const answer = response.data;
            addToChat("assistant", formatAnswer(answer), finalChatKey);
          } catch (error) {
            console.error("Error getting answer:", error);
          }
        }
      }
    },
    [user, chatKey, chatsPath, conversationsDisplayPath, chatsArray, subject, setChatKey]
  );

  // Syncing chat
  useSyncWithFirebase({
    setFunction: setChat,
    setIsLoading: setIsLoading,
    path: chatKey ? `${chatsPath}/${chatKey}` : null,
  });

  // Sending Question
  const sendQuestionHandler = useCallback(
    async (question) => {
      setIsLoading(true);
      addToChat("user", cleanUpInput(question));
    },
    [setIsLoading, addToChat]
  );

  // Asking initialQuestion from View Test Screen
  const hasInitialQuestionHandled = useRef(false);
  useEffect(() => {
    if (initialQuestion && !hasInitialQuestionHandled.current) {
      sendQuestionHandler("Explain this Step by Step" + JSON.stringify(initialQuestion));
      hasInitialQuestionHandled.current = true;
    }
  }, [initialQuestion, sendQuestionHandler]);

  const onSendImage = (imageUrl) => {
    latestImageRef.current = imageUrl;
    addToChat(
      "user",
      `<img src="${imageUrl}" alt="User uploaded image" class="${styles.chatImage}" />`
    );
  };

  // Checking for
  const isLastEntryAssistant = () => {
    const keys = Object.keys(chat);
    if (keys.length === 0) return false; // Handle empty object case

    // Sort keys and get the last key
    const lastKey = keys.sort().pop();

    // Check if the role of the last entry is 'assistant'
    return chat[lastKey]?.role === "assistant";
  };

  const UploadingAnimation = React.memo(({ isImageUploading }) => {
    if (!isImageUploading) return null;

    return (
      <div className={styles.cropContainer}>
        <LottieAnimation
          name="uploading"
          speed={0.25}
          animationStyle={{ width: 380, height: 450, alignSelf: "center" }}
        />
        <h4 style={{ color: "White" }}>Analyzing Image...</h4>
      </div>
    );
  });

  return (
    <div className={styles.chatContainer}>
      <div className={styles.chats}>
        {/* Rendering Chat */}
        {!isChatEmpty &&
          Object.entries(chat).map(([key, msg]) => {
            const isImage = msg.content.startsWith("<img");
            return (
              <div key={key} className={msg.role === "user" ? styles.rightChat : styles.leftChat}>
                {isImage ? (
                  <img
                    src={msg.content.match(/src="([^"]+)"/)[1]}
                    alt="Uploaded by User"
                    className={styles.chatImage}
                  />
                ) : (
                  <div dangerouslySetInnerHTML={{ __html: formatAnswer(msg.content) }} />
                )}
              </div>
            );
          })}

        {/* Question Suggestions */}
        {isLastEntryAssistant() && (
          <div className={styles.questionSuggestions}>
            {questionSuggestions.map((value) => (
              <PrimaryButton
                key={value}
                width="18%"
                mobileWidth="100%"
                name={value}
                color="#121212"
                marginRight={10}
                border="1px solid #1b98f5"
                marginBottom={10}
                backgroundColor={Colors.boxColor}
                hoverColor={Colors.boxHoverColor}
                onClick={() => sendQuestionHandler(value)}
              />
            ))}
          </div>
        )}

        {/* Empty Animation */}
        {isChatEmpty && !isLoading && !isImageUploading && (
          <div className={styles.animationContainer}>
            {brand?.brandOrganizationLogo && (
              <img
                alt="Brand Logo"
                src={brand?.brandOrganizationLogo}
                className={styles.brandOrganizationLogo}
              />
            )}

            <h4 className={styles.clickToAsk}>Click to Ask "{subject}" Doubts & Concepts</h4>
            <LottieAnimation
              name="arrow"
              animationStyle={{
                marginTop: 20,
                alignSelf: "center",
                width: isMobileDevice ? 120 : 150,
                height: isMobileDevice ? 120 : 150,
                marginLeft: isMobileDevice ? -50 : -100,
              }}
            />
          </div>
        )}

        {/* Books Loading Animation */}
        {isLoading && (
          <LottieAnimation
            name="books"
            animationStyle={{ width: 600, height: 600, alignSelf: "center" }}
          />
        )}

        {isImageUploading && <UploadingAnimation isImageUploading={isImageUploading} />}

        <div ref={chatEndRef} />
      </div>

      {/* Bottom Chat Section */}
      <DoubtsMessage
        onSendImage={onSendImage}
        onSendMessage={sendQuestionHandler}
        isImageUploading={isImageUploading}
        setIsImageUploading={setIsImageUploading}
      />
    </div>
  );
};

export default DoubtsChat;
