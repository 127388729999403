import React, { useState, useRef, useCallback } from "react";
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from "firebase/storage";
import "cropperjs/dist/cropper.css";
import Cropper from "react-cropper";
import Compressor from "compressorjs";
import { createPortal } from "react-dom";
import sendIcon from "../../assets/sendIcon.svg";
import cameraIcon from "../../assets/cameraIcon.svg";
import { useUserContext } from "../../utils/UserContext";
import PrimaryButton from "../../components/PrimaryButton";
import { giveHapticFeedback } from "../../functions/giveHapticFeedback";
import styles from "../../styles/doubtsStyles/DoubtsMessage.module.css";
// import LottieAnimation from "../../components/LottieAnimation";

const DoubtsMessage = ({ onSendMessage, onSendImage, setIsImageUploading }) => {
  const { loginCode } = useUserContext();
  const [message, setMessage] = useState("");
  const [imageToCrop, setImageToCrop] = useState(null);
  const cropperRef = useRef(null);
  const textareaRef = useRef(null);
  const storage = getStorage();

  // Handle changes in the textarea
  const handleChange = (e) => {
    setMessage(e.target.value);
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${Math.min(textareaRef.current.scrollHeight, 75)}px`;
    }
  };

  // Handle sending the text message
  const handleSend = () => {
    giveHapticFeedback();
    if (message.trim()) {
      onSendMessage(message);
      setMessage("");
      textareaRef.current.style.height = "auto";
    }
  };

  const handleCaptureImage = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const objectUrl = URL.createObjectURL(file);
    setImageToCrop(objectUrl);

    // Release the previous object URL to free up memory
    return () => URL.revokeObjectURL(objectUrl);
  };

  const compressImage = useCallback((blob) => {
    return new Promise((resolve, reject) => {
      new Compressor(blob, {
        quality: 0.6,
        maxWidth: 510,
        maxHeight: 510,
        success: resolve,
        error: reject,
      });
    });
  }, []);

  const handleCropAndUpload = async () => {
    const cropper = cropperRef.current?.cropper;
    if (!cropper) return;

    setIsImageUploading(true);
    setImageToCrop(null);

    try {
      // Step 1: Get Cropped Canvas and Convert to Blob (Offloaded to Async)
      const croppedCanvas = cropper.getCroppedCanvas();
      const blob = await new Promise((resolve, reject) => {
        croppedCanvas.toBlob((blob) => {
          if (blob) resolve(blob);
          else reject(new Error("Failed to generate Blob from canvas"));
        });
      });

      // Step 2: Compress the Blob (Async Operation)
      const compressedFile = await compressImage(blob);

      // Step 3: Upload to Firebase Storage (Async)
      const imageRef = storageRef(storage, `images/${loginCode}/${new Date().getTime()}`);
      await uploadBytes(imageRef, compressedFile);

      // Step 4: Get the Download URL
      const imageUrl = await getDownloadURL(imageRef);

      // Step 5: Pass the URL to the Parent Component
      onSendImage(imageUrl);
    } catch (error) {
      console.error("Error during image cropping or uploading:", error);
    } finally {
      // Step 6: Clean Up and Reset UI States
      cropperRef.current?.cropper?.destroy();
      setIsImageUploading(false);
    }
  };

  return (
    <div className={styles.messageContainer}>
      <div className={styles.textareaContainer}>
        <textarea
          rows={1}
          value={message}
          ref={textareaRef}
          onChange={handleChange}
          className={styles.textInput}
          placeholder="Ask Doubts / Concepts"
        />
        <input
          type="file"
          accept="image/*"
          capture="environment"
          style={{ display: "none" }}
          id="cameraInput"
          onChange={handleCaptureImage}
        />
        <label htmlFor="cameraInput" className={styles.cameraButton}>
          <img src={cameraIcon} alt="Camera" className={styles.cameraIcon} />
        </label>
      </div>
      <button className={styles.sendButton} onClick={handleSend}>
        <img src={sendIcon} alt="Send" />
      </button>

      {/* Full-screen crop overlay using React Portal */}
      {imageToCrop &&
        createPortal(
          <div className={styles.cropContainer}>
            <Cropper
              src={imageToCrop}
              className={styles.cropper}
              initialAspectRatio={1}
              guides={false}
              ref={cropperRef}
              viewMode={1}
              responsive={true}
              autoCropArea={Cropper}
              background={false}
            />
            <PrimaryButton name="Crop Question" onClick={handleCropAndUpload} marginTop={20} />
          </div>,
          document.body
        )}
    </div>
  );
};

export default DoubtsMessage;
